import React from 'react'
import { Link } from "gatsby-plugin-intl"
// import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
// import Button from "../components/Button"
// import Legend from "../components/Legend"
import { Grid, Row, Col } from 'react-flexbox-grid'
// import ScrollIntoView from 'react-scroll-into-view'

// import logoFull from "../assets/svg/logo-full.svg"
// import scrollDown from "../assets/svg/scroll-down.svg"
// import scrollTop from "../assets/svg/scroll-top.svg"
import bgEsper from "../assets/svg/bg-esperluette.svg"
// import bgEsperHome from "../assets/svg/bg-esperluette-home.svg"
// import pastImplan from "../assets/svg/pastilleJaune.svg"
// import pastInter from "../assets/svg/pastillemarron.svg"

// import SVGMap1200 from "../images/carte_1200px.svg"
// import { TooltipParis, TooltipVenise, TooltipTokyo } from "../components/MapTooltips.js"
// import { ZoneParis, ZoneVenise, ZoneTokyo } from "../components/MapZones.js"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="section-wrapper" id="errordocument">
      <img className="bg-esper" src={bgEsper} alt="" />
      <Grid fluid>
        <Row className="hat">
          <Col mdOffset={1} xs={12} md={5}>
            <p className="categorie">Code d'erreur : 404</p>
            <h1>Oups !</h1>
            <h2>La page que vous recherchez semble introuvable.</h2>
            <p><strong>Voici quelques liens utiles à la place :</strong></p>
            <ul>
              <li><Link to="#presentation">Présentation</Link></li>
              <li><Link to="#expertises">Expertises</Link></li>
              <li><Link to="#international">International</Link></li>
              <li><Link to="#management">Management</Link></li>
              <li><Link to="#contact">Contact</Link></li>
            </ul>
          </Col>
        </Row>
      </Grid> 
    </section>
  </Layout>
)

export default NotFoundPage
